import logo from './szwagrkinia.png';
import './App.css';
import Typography from '@mui/material/Typography';

function App() {
  return (
    <div className="App">
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
        </head>
      <header className="App-header">
        <Typography
            variant="h1"
            gutterBottom
            style={{ fontFamily: "Dancing Script", color: "goldenrod"}}
            class={"gorny"}
        >
          Państwo Czerwińscy
        </Typography>
      </header>
      <body className="App-body">
      <img src={logo} className="App-logo" alt="logo" />
          <div className="Wish-text-cont">
              <Typography
                  variant="h2"
                  gutterBottom
                  style={{ fontFamily: "Dancing Script", color: "goldenrod"}}
                  class={"gorny"}
              >
                  Serdeczne życzenia dla Państwa Czerwińskich składają Nikodem Olszowy i Julia Domachowska
              </Typography>
          </div>
      <div className="Wish-text-cont">
          <Typography
              variant="h2"
              gutterBottom
              style={{ fontFamily: "Dancing Script", color: "goldenrod"}}
              class={"gorny"}
          >
              W życiu na wszystko trzeba sobie zapracować, nawet na prezent ślubny. Aby otworzyć kłódkę rozwiąż następującą zagadkę:
          </Typography>
      </div>
      <div className="Wish-text-cont-2">
          <Typography
              variant="h3"
              gutterBottom
              style={{ fontFamily: "Dancing Script", color: "gray"}}
              class={"gorny"}
          >
              " Dziś wyszła Pani, co na "cz" ma nazwisko ,
          </Typography>
          <Typography
              variant="h3"
              gutterBottom
              style={{ fontFamily: "Dancing Script", color: "gray"}}
              class={"gorny"}
          >
              Niech nigdy nie zapomni tych weselnych godzin,
          </Typography>
          <Typography
              variant="h3"
              gutterBottom
              style={{ fontFamily: "Dancing Script", color: "gray"}}
              class={"gorny"}
          >
              Gałgan co jej skarb zamknął już zapomniał wszytko,
          </Typography>
          <Typography
              variant="h3"
              gutterBottom
              style={{ fontFamily: "Dancing Script", color: "gray"}}
              class={"gorny"}
          >
              Prócz ostatnich trzech cyfr z ów Pani narodzin."
          </Typography>
      </div>
      </body>
    </div>
  );
}

export default App;
